.corporate-table td{
  font-size: 14px;
  color: #47505F;
}

table tbody tr:nth-child(even) {
  background-color: white;
}
table thead tr {
  position: sticky;
  top: 0;
  background-color: white;
}
.footer-content{
  background-color: white;
  border-radius: 8px;
  padding: 20px;
}

.footer-viwe select{
  border: 1px solid gray;
  border-radius: 8px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.active{
  background-color: #FCDD00;
  color: #1A1A1A;
  border-radius: 8px;
  padding: 12px 12px;
  width: 100%;
} 
.sidebar li:hover {
  background: #FFFCE1;
  border-radius: 8px;
}

/* for login background */
.card {
  width: 100%;
  padding: 0.5em 1em;
  vertical-align: middle;
}
.bg-gold {
  background: -webkit-linear-gradient(98deg, #fff 50%, #FFFCE1 50%);
  background: -o-linear-gradient(98deg, #fff 50%, #FFFCE1 50%);
  background: -moz-linear-gradient(98deg, #fff 50%,#FFFCE1 50%);
  background: linear-gradient(98deg, #fff 50%, #FFFCE1 50%);
}

/* for delete pop  */
.custom-confirm-button:focus{
  outline: none;
  box-shadow: none !important;
  color: #000;
}
.custom-confirm-button:hover{
  opacity: unset;
  background:  #FCDD00 !important;
}
.custom-cancel-button{
  color: #000 !important;
  box-shadow: none !important;
}
.custom-cancel-button:hover{
  background:  #FFFCE1 !important;
}
.custom-select {
  max-height: 200px; 
  overflow-y: auto;
}

/* //for tooltip */

.del-tooltip {
  position: relative;
  z-index: 100 !important;
}

.del-tooltip .del-tooltiptext {
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: 0px 0px 2px #B2B2B2;
  visibility: hidden;
  width: 120px;
  background-color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 4px;
  position: absolute;
  z-index: 1 !important;
  top: 130%;
  left: 50%;
  margin-left: -60px;
}


.del-tooltip .del-tooltiptext::after {
  background-color: #fff;
  box-shadow: -1px -1px 0px 0 rgba( 178, 178, 178, .4);
  content: "\00a0";
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: -5px;
  transform: rotate( 45deg);
  -moz-transform: rotate( 45deg);
  -ms-transform: rotate( 45deg);
  -o-transform: rotate( 45deg);
  -webkit-transform: rotate( 45deg);
  margin-top: 2px;
}

.del-tooltip:hover .del-tooltiptext {
  visibility: visible;
}

/* for delete corportae search tooltip */
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: 0px 0px 2px #B2B2B2;
    visibility: hidden;
  /* width: 120px; */
  /* max-height: 100px; */
  background-color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 4px;
  position: absolute;
  z-index: 40;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "\00a0";
  display: block;
  margin-left: 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: -5px;
  margin-top: 2px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-width: 991px) {
  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
  .bg-gold {
    background: none;
  }
  .right-container{
    background-color: #FFFCE1;
    padding: 30px 0px;
  }
  .left-container{
    padding-top: 50px;
  } 
}
@media only screen and (max-width: 500px) {
  .login-form{
    padding: 10px;
    margin: 10px;
    width: 100%;
  }  
}

  
